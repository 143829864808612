import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

// const { PUBLIC_URL } = process.env;

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        {/* <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" /> */}
      </Link>
      <header>
        <h2>Aakash Deep</h2>
        <p>
          <a href="mailto:aakashdeep983@gmail.com">aakashdeep983@gmail.com</a>
        </p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>SOFTWARE ENGINEER || KIITIAN || FIGHTER || HIGH JUMP SOON</p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? (
            <Link to="/resume" className="button">
              Learn More
            </Link>
          ) : (
            <Link to="/about" className="button">
              About Me
            </Link>
          )}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">
        &copy; {new Date().getFullYear()} Created by Aakash Deep. All rights
        reserved.<Link to="/">eraakashdeep.com</Link>.
      </p>
    </section>
  </section>
);

export default SideBar;
